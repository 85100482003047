import { useCallback, useRef, useState } from "react"
import ReactPlayer, { type ReactPlayerProps } from "react-player"
import { Button } from "./Button"
import { darkerGreen, darkGreen, lightGreen, lightText } from "../../styles/Index.Styles"

import FastForwardIcon from "@mui/icons-material/FastForward"
import FastRewindIcon from "@mui/icons-material/FastRewind"
import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import VolumeOffIcon from "@mui/icons-material/VolumeOff"
import AutoSyncButton from "./AutoSyncButton"
import type { IMedia } from "../media/Interfaces.media"
import { Box, Typography, Slider } from "@mui/material"

type VideoPlayerProps = ReactPlayerProps & {
    mediaData: IMedia | Record<PropertyKey, never>
}

export default function VideoPlayer({ mediaData, url }: VideoPlayerProps) {
    const videoRef = useRef<ReactPlayer | null>(null)
    const playerContainerRef = useRef<HTMLDivElement | null>(null)

    const [isPlayingMedia, setIsPlayingMedia] = useState<boolean>(false)
    const [volume, setVolume] = useState<number>(0.5)

    function skipForward() {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime + 0.5, "seconds")
        }
    }

    function skipBackward() {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime - 0.5, "seconds")
        }
    }

    const handlePlay = useCallback(() => {
        setIsPlayingMedia(true)
    }, [])

    const handlePause = useCallback(() => {
        setIsPlayingMedia(false)
    }, [])

    const togglePlayPause = useCallback(() => {
        if (videoRef.current) {
            const isPlaying =
                videoRef.current.getCurrentTime() > 0 &&
                !videoRef.current.getInternalPlayer().paused
            isPlaying
                ? videoRef.current.getInternalPlayer().pause()
                : videoRef.current.getInternalPlayer().play()
            setIsPlayingMedia(!isPlaying)
        }
    }, [])

    const handleVolumeChange = useCallback((event: Event, newValue: number | number[]) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue
        setVolume(value / 100)
    }, [])

    function seekTo(seconds: number) {
        if (videoRef.current) {
            videoRef.current.seekTo(seconds)
        }
    }

    return (
        <>
        <Box
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Typography
                style={{
                    color: darkerGreen,
                    fontSize: '18px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                }}
            >
                {mediaData.title}
            </Typography>
        </Box>
        <Box
            style={{ position: "relative", width: "100%", height: "100%", marginTop: '8px' }}
            ref={playerContainerRef}
        >
            <ReactPlayer
                ref={videoRef}
                url={url}
                playing={isPlayingMedia}
                volume={volume}
                width="100%"
                height="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                controls={false}
                config={{
                    file: {
                        attributes: {
                            playsInline: true,
                            controlsList: 'nodownload noplaybackrate nocaptions nofullscreen',
                            disablePictureInPicture: true,
                        }
                    }
                }}
            />
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '48px',
            }}
            paddingX='16px'
        >
            <Button onClick={skipBackward}>
                <Button.Icon icon={FastRewindIcon} iconColor={lightGreen} size="60px" />
            </Button>

            {isPlayingMedia ? (
                <Box style={{ backgroundColor: darkGreen, borderRadius: '50%' }}>
                    <Button onClick={togglePlayPause}>
                        <Button.Icon icon={PauseIcon} iconColor={lightText} />
                    </Button>
                </Box>
            ) : (
                <Box style={{ backgroundColor: darkGreen, borderRadius: '50%' }}>
                    <Button onClick={togglePlayPause}>
                        <Button.Icon icon={PlayArrowIcon} iconColor={lightText} />
                    </Button>
                </Box>
            )}

            <Button onClick={skipForward}>
                <Button.Icon icon={FastForwardIcon} iconColor={lightGreen} size="60px" />
            </Button>
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                paddingTop: '48px',
            }}
            paddingX='16px'
        >
            <AutoSyncButton
                mediaData={mediaData}
                seekTo={seekTo}
                onPause={handlePause}
                onPlay={handlePlay}
            />

            <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {volume === 0 && (
                    <Button onClick={() => setVolume(0.5)}>
                        <Button.Icon
                            icon={VolumeOffIcon}
                            iconColor='black'
                            size="24px"
                        />
                    </Button>
                )}
                {volume !== 0 && (
                    <Button onClick={() => setVolume(0)}>
                        <Button.Icon
                            icon={VolumeUpIcon}
                            iconColor='black'
                            size="24px"
                        />
                    </Button>
                )}
                <Slider
                    value={volume * 100}
                    onChange={(e, value) => handleVolumeChange(e, value)}
                    min={0}
                    max={100}
                    style={{ width: "70px", color: 'black', height: '2px' }}
                />
            </Box>
        </Box>
        </>
    )
}